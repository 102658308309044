<template>
  <div class="lecturer scroll-box" @scroll="scrollBox($event)">
    <TopBar :type="2" :pageTitle="pageTitle" />
    <div class="options" v-if="pageType == 1">
      <p class="title">等级</p>
      <ul class="option-box clearfix">
        <li
          class="option"
          :class="{ active: level == 0 }"
          @click="getResultsList(industry, 0)"
        >
          不限
        </li>
        <li
          class="option"
          :class="{ active: level == key }"
          v-for="(value, key) in searchParams.level"
          :key="value"
          @click="getResultsList(industry, key)"
        >
          {{ value }}
        </li>
      </ul>
      <p class="title">行业</p>
      <ul class="option-box clearfix industry" :class="{ open: fold }">
        <li
          class="option"
          :class="{ active: industry == 0 }"
          @click="getResultsList(0)"
        >
          不限
        </li>
        <li
          class="option"
          :class="{ active: industry == key }"
          v-for="(value, key) in searchParams.industry"
          :key="value"
          @click="getResultsList(key)"
        >
          {{ value }}
        </li>
      </ul>
      <p class="fold-btn" :class="{ open: fold }" @click="fold = !fold">
        {{ fold ? "收起" : "展开" }}
      </p>
       <p class="title yyzTitle">是否支持元宇宙跨空间培训<a href="https://npx.kskstudy.com/activity/detail?id=32" target="_blank" rel="noopener noreferrer">
                 <img src="@/assets/tip_icon.png" alt="">
               </a></p>
      <ul class="option-box clearfix industry" :class="{ open: fold }">
        <li
          class="option"
          :class="{ active: yyzIndex == 1 }"
          @click="changeYyz(1)"
        >
          是
        </li>
        <li
          class="option"
          :class="{ active: yyzIndex == 2 }"
          @click="changeYyz(2)"
        >
          否
        </li>
      </ul>
    </div>
    <div class="options" v-if="pageType == 2">
      <ul class="option-box clearfix">
        <li
          class="option"
          :class="{ active: categoryC == 0 }"
          @click="getSubCategoryList(0)"
        >
          不限
        </li>
        <li
          class="option"
          :class="{ active: categoryC == op.id }"
          v-for="op in category"
          :key="op.id"
          @click="getSubCategoryList(op.id, 'op')"
        >
          {{ op.name }}
        </li>
      </ul>
      <p class="fold-btn" :class="{ open: fold }" @click="fold = !fold">
        {{ fold ? "收起" : "展开" }}
      </p>
    </div>
    <ul class="list" v-if="pageType == 1">
      <li class="info" v-for="item in resultsList" :key="item.id">
        <div class="img" @click="goHomePage(item)">
          <img v-if="item.avatar" :src="item.avatar" class="info-img" />
          <img v-else src="@/assets/per_img.png" class="info-img" />
        </div>

        <div class="info-content">
          <div class="info-name" @click="goHomePage(item)">
            <span>{{ item.name }}</span>
            <div class="marks" >
              <div class="mark" v-for="mark in item.teacher_tags" :key="mark">
                {{ mark }}
              </div>
            </div>
          </div>
          <p class="info-intr" @click="goHomePage(item)">{{ item.introduce }}</p>
          <router-link class="info-btn" :to="'/lecturerHomepage/' + item.id"
            >进入主页</router-link
          >
        </div>
      </li>
    </ul>
    <ul class="list subject" v-if="pageType == 2">
      <li
        class="info"
        v-for="item in subjectList"
        :key="item.id"
        @click="$router.push('/seriesLessonsInfo/' + item.id)"
      >
        <div class="img-box">
          <img :src="item.image" class="info-img" />
        </div>
        <div class="info-content">
          <div class="info-name">
            <span>{{ item.name }}</span>
          </div>
          <p class="info-intr">{{ item.duration }}课时</p>
          <p class="info-price" v-if="websiteData.is_course_pay==1"><span class="unit">￥</span>{{ item.price }}</p>
        </div>
      </li>
    </ul>
    <div
      class="nodata"
      v-if="
        resultsList &&
        resultsList.length == 0 &&
        subjectList &&
        subjectList.length == 0
      "
    >
      <img class="nodata-img" :src="require('@/assets/no-data.png')" />
      <p class="nodata-text">当前搜索无结果</p>
    </div>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
export default {
  name: "lecturer",
  data() {
    return {
      more: false, //是否有更多数据
      pageTitle: "",
      pageType: 0,
      fold: false, //是否展开
      searchParams: {}, //搜索条件列表
      resultsList: [], //搜索结果列表
      industry: 0, //领域
      level: 0, //等级
      category: null, //系列课筛选条件
      categoryC: 0, //系列课筛选条件选中
      subjectList: [], //系列课结果列表
      nowPage: 1, //当前页
      websiteData:'',
      yyzIndex:1,
    };
  },
  components: {
    TopBar,
  },
  mounted() {
    this.websiteData = JSON.parse(localStorage.getItem('websiteState'))
    if (this.$route.name == "SeriesLessons") {
      //系列课
      this.pageType = 2;
      this.pageTitle = "专题";
      this.getSubCategory();
    } else if (this.$route.name == "Lecturer") {
      //讲师
      this.pageType = 1;
      this.pageTitle = "讲师";
      this.getSearchParams(); //获取讲师列表
    }
  },
  methods: {
    //获取讲师列表
    getSearchParams() {
      this.$axios.post(`/v1/teacher/searchParams`).then((res) => {
        this.searchParams = res.data;
        this.getResultsList();
      });
    },
    //获取讲师列表
    getResultsList(industry, level, fanye) {
      if(this.yyzIndex==2){
         this.resultsList=[]
        
        return
      }
      if (!fanye) {
        if (industry || industry == 0) {
          this.industry = industry;
          this.nowPage = 1;
          this.resultsList = [];
        } else {
          this.industry = 0;
        }

        if (level || level == 0) {
          this.level = level;
          this.nowPage = 1;
          this.resultsList = [];
        } else {
          this.level = 0;
        }
      }

      this.$axios
        .post(`/v1/teacher/list`, {
          industry: this.industry,
          level: this.level,
          page: this.nowPage,
          pre_page: this.limit,
        })
        .then((res) => {
          this.resultsList = [...this.resultsList, ...res.data.data];

          if (res.data.total > this.resultsList.length) {
            this.more = true;
          } else {
            this.more = false;
          }
        });
    },
    changeYyz(num){
      this.yyzIndex = num
      this.getResultsList()
    },
    //获取分类-系列课
    getSubCategory() {
      this.$axios.post(`/v1/subject/category`).then((res) => {
        this.category = res.data;
        this.getSubCategoryList(0);
      });
    },
    getSubCategoryList(id, op) {
      this.categoryC = id;

      if (op) {
        this.nowPage = 1;
        this.subjectList = [];
      }

      this.$axios
        .post(`/v1/subject/list`, {
          category_id: id,
          page: this.nowPage,
          pre_page: this.limit,
        })
        .then((res) => {
          this.subjectList = [...this.subjectList, ...res.data];

          if (res.total > this.subjectList.length) {
            this.more = true;
          } else {
            this.more = false;
          }
        });
    },
    //翻页
    scrollBox(e) {
      if (
        e.target.scrollHeight - e.target.clientHeight == e.target.scrollTop &&
        this.more
      ) {
        this.nowPage++;
        if (this.pageType == 1) {
          this.getResultsList(this.industry, this.level, "fanye");
        } else {
          this.getSubCategoryList(this.categoryC);
        }
      }
    },
    // 跳转到讲师详情
    goHomePage(item) {
      this.$router.push("/lecturerHomepage/" + item.id);
    },
  },
  watch: {
    //监听路由判断换题
    $route() {
      location.reload();
    },
  },
};
</script>
<style scoped lang="scss">
.options {
  padding: 20px 8px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  .title {
    padding-left: 20px;
    font-size: 28px;
    color: #666;
    margin-bottom: 10px;
  }
  p.yyzTitle{
    margin-top: 20px;
    img{
      width: 40px;
      margin-left: 5px;
    }
  }
  .option-box {
    &.industry {
      height: 150px;
      overflow: hidden;
      &.open {
        height: auto;
      }
    }
  }
  .option {
    padding: 5px 10px;
    background: #f5f5f5;
    border-radius: 6px;
    font-size: 26px;
    color: #999;
    float: left;
    margin: 14px 20px;
    border: 1px solid #f5f5f5;
    &.active {
      border-color: #254ed4;
      color: #254ed4;
      background: none;
    }
  }
}
.fold-btn {
  font-size: 24px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url(~@/assets/arrows-down-ico.png) right center no-repeat;
    background-size: 100% auto;
    margin-left: 10px;
  }
  &.open::after {
    transform: rotate(180deg);
  }
}
.list {
  padding: 14px;
  .info {
    overflow: hidden;
    padding: 14px;
  }
  .img {
    width: 158px;
    height: 208px;
    overflow: hidden;
    float: left;
    .info-img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      border-radius: 6px;
    }
  }
  .info-content {
    width: 500px;
    height: 208px;
    float: left;
    margin-left: 20px;
    position: relative;
  }
  .info-name {
    display: flex;
    align-items: center;
    font-size: 28px;
    color: #333;
    .marks {
      margin-bottom: 0;
      .mark {
        padding: 6px 10px;
        background: rgba(255, 195, 11, 0.14);
        border-radius: 18px 0px 18px 0px;
        font-size: 24px;
        color: #f5a623;
        margin-left: 10px;
      }
    }
  }
  .info-intr {
    height: 70px;
    font-size: 26px;
    color: #999;
    line-height: 35px;
    padding-top: 20px;
    //多行省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .info-btn {
    width: 128px;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #254ed4;
    background-color: #fff;
    font-size: 26px;
    text-align: center;
    line-height: 42px;
    color: #254ed4;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &.subject {
    .img-box {
      width: 308px;
      height: 200px;
      float: left;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 294px;
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(195, 204, 255, 0.14) 0%,
          rgba(125, 134, 168, 0.17) 45%,
          rgba(72, 82, 103, 0.3) 100%
        );
        box-shadow: 0px 1px 0px 0px #f5f5f5;
        border-radius: 6px;
        border-image: linear-gradient(
            180deg,
            rgba(159, 159, 159, 0),
            rgba(72, 82, 103, 0.07)
          )
          1 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 6px auto;
        z-index: 1;
      }
      &::after {
        content: "";
        display: block;
        width: 274px;
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(72, 82, 103, 0.13) 100%
        );
        box-shadow: 0px 1px 0px 0px #f5f5f5;
        border-radius: 6px;
        border-image: linear-gradient(
            180deg,
            rgba(159, 159, 159, 0),
            rgba(72, 82, 103, 0.06)
          )
          1 1;
        position: absolute;
        left: 0;
        right: 0;
        margin: 12px auto;
        z-index: 0;
      }
    }
    .info-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .info-content {
      width: 360px;
    }
    .info-name {
      height: 80px;
      line-height: 40px;
      //多行省略号
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .info-price {
      font-size: 32px;
      color: #cd1b14;
      .unit {
        font-size: 24px;
      }
    }
  }
}
.nodata {
  width: 574px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: center;
  .nodata-img {
    width: 100%;
    height: auto;
  }
  .nodata-text {
    font-size: 26px;
    color: #999;
    padding-top: 40px;
  }
}
</style>